@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

.share-info-header {
    z-index: 5;
    border-radius: 15px 15px 0px 0px;
    background: #E4F3FF;
    box-shadow: 0px 1px 10px 2px rgb(102 173 255 / 45%);
    width: 660px;
    height: 30px;
}

.share-info-body {
    
    width: 660px;
    border-radius: 0px 0px 8px 8px;
    filter: drop-shadow(6px 6px 17px rgba(0, 0, 0, 0.25));
    background: #fff;
    padding: 0px !important;

}

.vl {
    border-left: 1px solid #97C0F1;
    height: 35px;
}
.share-tab .nav-link {
/* background: #FFF; */
box-shadow: 0px -2px 4px 0px rgb(102 173 255 / 45%) inset;
  z-index: 3;
  /* box-shadow: inset -2px -2px 10px 1px rgba(0, 0, 0, 0.25); */
  /* border-bottom: 1px solid #adc6e5; */
  border-radius: 0 0 0 0;
}
.share-tab .active {
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 0%);;
    background: linear-gradient(0deg, rgb(228 240 255) 0%, rgb(236 245 255) 35%, rgb(208 230 255/60%) 100%) !important;
    color: #000;
    border: transparent;
    border-radius: 0 0 0 0;
    z-index: 10;
}
.nav-link:focus{
    color: #000 !important;
}
.h2-heading {
    color: #000 !important;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background: linear-gradient(122deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    border-radius: 13px;
    background: linear-gradient(122deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
}

.cards-body {
    max-width: 660px;
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(180deg, #E6F1FF 0%, #A5CEFF 100%);
    border: transparent;
}

.email-drop {
    border-radius: 62px;
    border: 2px solid #72A6E5;
    background: #FFF;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    width: 500px;
    padding: 10px;
    margin-top: 10px;
}

.email-cont {
    background-color: transparent;
    border: none;
    padding-left: 10px;
    width: 400px;
}

.email-view-drop {
    background-color: transparent;
    color: #858585;
}

.eml-btn {
    border-radius: 60px;
    background: linear-gradient(0deg, #000258 0%, #0053B4 100%);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    border: none;
    padding: 0px 15px 0px 15px;
    margin-top: 12px;
}
.emil-btn {
    border-radius: 60px;
    background: linear-gradient(0deg, #000258 0%, #0053B4 100%);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    border: none;
    padding: 0px 16px 0px 10px;
    /* margin-top: ; */

}
.cover-section {}

.email-place {
    width: 550px;
    padding: 20px;
}

.eml-to {
    border-radius: 0px 0px 0px 0px;
    background: #FFF;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
}

.scroll-in-it {
    overflow-y: auto;
    max-height: 120px;
}

.shr-btnn {
    padding: 20px;
}

.owner-viewer {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25) inset;
    height: 200px;
}
.share_public{
  border-radius: 74px;
  background: linear-gradient(0deg, #000258 0%, #0053B4 100%);
  color: #fff;
  padding: 10px 15px;
  border: none;
}
.disabled{
    background: linear-gradient(0deg, #00025885 0%, #0053b48f 100%);

}
.owner-viewer-2 {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25) inset;
    height: 95px;
    width: 470px;
    margin-left: 75px;
}

.scroll-in-it-2 {
    overflow-y: scroll;
    height: 100px;
    max-width: 550px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 17px;
    top: 4px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid blue;
}


.slider:before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 2px;
    bottom: 3px;
    background: var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%));
    -webkit-transition: .8s;
    transition: .8s;
}

input:checked+.slider:before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 2px;
    bottom: 3px;
    background: white;
    -webkit-transition: .6s;
    transition: .6s;
} 
input:checked+.slider {
    background: var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%));
} 

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.box-msg {
    border-radius: 15px !important;
    /* background: #FFF; */
    padding: 20px;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25) inset;
    /* margin-left: 75px; */
    text-align: center;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.tab-border{
    border-radius: 15px !important;
}

.open-box {
    width: 100px;
    height: 35px;

    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.email-tab{
    margin-top: 10px;
}
.email-drop-2 {
    /* margin-top: ; */
    border-radius: 62px;
    border: 2px solid #72A6E5;
    background: #FFF;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    width: 500px;
    padding: 10px;
    
}
.email-drop-3 {
    /* margin-top: ; */
    border-radius: 62px;
    border: 2px solid #72A6E5;
    background: #FFF;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    /* width: 500px; */
    padding: 10px;
    
}
.email-cont:focus{
    outline: none !important;
}
.email-drop-2:focus {
    border-radius: 62px;
    outline: 1px solid #72A6E5 !important;
  }
  .email-cont-2:focus{
    outline: transparent !important;
  }
.email-cont-2 {
    background-color: transparent;
    border: none;
    padding-left: 5px;
    width: 490px;
}
.switch-check{
    cursor: pointer;
}
.switch-check:checked{
    background-color: #000258  !important;
    /* background: var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%)) !important; */
    /* border-color: #000258  !important; */
}
.switch-check:focus{ border-color: #134591 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0 #fbfbfb00 !important;
}

/* .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
} */

/* h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: -0.1rem !important;
} */

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    border-color: transparent !important;
}

#email-list {
    list-style: none;
    padding: 0;
    display: flex;
    width: 600px;
    flex-wrap: wrap;
}

#email-list li {
    margin-bottom: 10px;
    display: flex;
    padding: 10px;
    border-radius: 47px;
    background: #fff;
    margin: 10px;
}

.close {
    right: 0;
    top: 0;
    padding-left: 5px;
}

.close:hover {
    background-color: #808080;
    color: white;
    border-radius: 47px;
}

#pass-status {
    color: black;
    margin-left: -30px;
}

#password-3 {
    -webkit-text-security: disc;
    -moz-text-security: circle;
    text-security: circle;
}

#myDIV-5 {
    /* display: none; */
    border-radius: 15px;
    /* background: #FFF; */
    /* box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25) inset; */
    width: 550px;
    height: 50px;
    margin-left: -85px;
    text-align: center;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 10px;
}

#myDIV-6 {
    /* display: none; */
    border-radius: 15px;
    background: #FFF;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25) inset;
    width: 250px;
    height: 70px;
    margin-left: 75px;
    text-align: center;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 10px;
}

.public-link-feature{
    border: none;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    color: #000;
}
.public-link-feature.active{
    color: #000 !important;
}
.fetures-tab .nav-link {
    background-color: #ffff;
    /* box-shadow: 0px 0px 4px 0px rgb(102 173 255 / 45%) inset; */
    z-index: 3;
    border-radius: 0 0 0 0;
}
.fetures-tab .active {
    /* box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 0%);; */
    background: linear-gradient(0deg, rgb(228 240 255) 0%, rgb(236 245 255) 35%, rgb(208 230 255/60%) 100%) !important;
    /* color: #000; */
    
    border-radius: 0 0 0 0;
    z-index: 10;
}
.icon-style-public-share{
    background: var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.react-share__ShareButton{
    margin-right: 10px;
  }