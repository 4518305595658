.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.contact-map{
  width: 600px;
  height: 450px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  
  margin:0;
  padding:0;
  height:100%;
  width: 100%;
  overflow-x: hidden;
}
.color-blk{
  color:#2945a2;
}
.fsize-3rem{
  font-size:2.5rem;
}
.side-img-l-page{
  width: 520px;
  /*animation: mymove 3s infinite;*/
  
}
.w-600{
  font-weight: 600;
}
.container-fluid{
  padding-right: 0px;
  padding-left: 0px!important;
}
/* @keyframes mymove {
  0% {margin-top: 0px;}
  
  50% {margin-top: 10px;}
  
  100%{margin-top :0px}
} */
.row{
  margin-right: 0px;
  
}
.row>*{
  padding-right: 0px!important;
}
.row-left-margin{
  padding-right: 0px!important;
}
.Amaranth{
    font-family:Amaranth;
}
.Archivo{
  font-family:Archivo;
}
.btn-primary{
  background-color: #2945a2!important;
}
.text-primary{
  color: #2945a2!important;
}
.title-fill{
  
  text-align: center;
  border: 1px solid #abb2b8;
  color:#2945a2;
  font-size: 16px;
  font-weight: 600;
  width: 50%;
  background-color: #e0e0e0;
}
.title-fill::placeholder{
  font-size: 16px;
}
.title-fill:focus-visible{
  outline: -webkit-focus-ring-color auto 0px;
}
.padlock-img{
  height: 230px;
  width: 80%;
 
}
.card-otp-success-upload{
  max-width: 500px;
  margin-left: 100px;
  padding-bottom: 25px;
  margin-top: 45px;
}
.side-text-box{
  margin-right: 100px;
}
.sign-up-side-text-btn{
  padding:5px 40px;
  border: none;
  font-weight: 600;
  color:#000;
  font-size: 15px;
  background-color: #96d0e5;
  text-decoration: none;
  font-size: 18px;
  
}
.spacer-200{
  margin-top: 150px;
}
.otpverify-a{
  color: #fff;
  border: none;
  width: 150px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 15px;
  background-image: linear-gradient(to top, #132862 0%, #4581ae 100%);
}
.otpverify-a:disabled{
  opacity: 0.8;
  background-image: linear-gradient(to top, #7f7f7f 0%, #9d9d9d 100%);
}
.main-section{
  height: 100vh;
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
}
.bucket-video{
  width: 100%;
  height: auto;
}
.spacer-big{
  height:150px;
}
.logo-img{
  width:180px; 
  height:auto;
  margin-left: 20px;
  margin-top: 10px;
}
.navbar-sec{
  padding-right: 0px;
}
.nav-sec-nav{
  background-color: #96d0e5;
  margin-top: 25px;
  padding-right: 20px;
  border-radius: 20px 0px 0px 20px;
  position: -webkit-sticky;
  position: sticky;
  transition: 1s;
  position: relative;

}
.otpField-head{
  font-weight: 700;
  font-size: 18px;
}
.nav-link-c{
  color: #183955;
  font-weight: 700;
  background-color: transparent;
  border: none;
  font-size: 14px;
}
.success-msg-div{
  margin-left: 10%;
  margin-right: 10%;
}
.show-size-btn{
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
  color:#fff;
  border: none;
  border-radius: 0px 0px 5px 5px;
  padding:2px 8px;
  font-size: 15px;
}
.otpField-text{
  font-size: 15px;
}
.side-text-h{
  font-size: 36px;
  font-weight: 600;
}
.spacer-50{
  margin-top: 100px;
}
.spacer-75{
  margin-top: 75px;
}
.succes-check{
  width:250px;
  height: auto;
}
.spacer-10{
  margin-top:50px;
}
.in-btn{
  background-color: #fff!important;
  border:none!important;
}
.remove-border{
  border: 0px 0px 0px 1px!important;
}
#folderopen{
  background-color: transparent;
  color: #fff;
  border:none;
  font-size: 15px;
  font-weight: 600;
}
#fileopen{
  background-color: transparent;
  color: #484a4d;
  border:none;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 3px;
}
.width-100{
  width:100%;
}
.input-box{
  border:1px solid #abb2b8 ;
  border-radius: 10px;
  font-size: 15px;
  color: #adb4ba;
}
.input-box::placeholder{
  color:#b7b8c1;
}
.bg-fafafa{
  background-color: #fafafa;
}
.msg{margin-bottom: 2px;}
.bg-file-tsfr{
  border-radius: 5px;
}
.file-card-name{
  color: #484a4d;
  font-size: 13px;
  display: block;
  white-space: nowrap;
  /* width: 17em; */
  overflow: hidden;
  text-overflow: ellipsis;
  /*border-bottom: 1px solid #c5c5c5;*/
  padding: 5px;
  
}
.scroll-div{
  max-height: 90px;
}
.verify-btn{
  background-color: #fff!important;
  border:none!important;
  color:#2945a2;
  text-decoration: underline;
  margin-top: -10px;
  padding-top:0 ;

}

.scroll-div::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px #00000040 inset;
}

.scroll-div::-webkit-scrollbar-thumb:hover {
  background-color: #00000040;
  border: 0px solid transparent;
  box-shadow: none;
}
.plus-img-ico{
  height: 40px;
  margin-top: 10px;
  width: auto;
}
.more-btn{
  height:25px;
  width:auto;
  
}
.bg-transparent{
  background-color: transparent!important;
  border:none!important;
}
.dropdown-toggle::after{
  display:none;
}
.transfer-img{
  width:250px;
  height:auto;
}
.loader-img{
  width:60px;
  height:60px;
}
.main-div-dl-page{
  height: 100vh;
  /*background-image: url('bg.png');*/
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.left-1st-text{
  font-size: 25px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
}
.space-100{
  margin-top: 180px;
}
.left-2nd-text{
  font-size: 36px;
  font-family: Arimo;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  color: #2945a2;

}
.ready-to-download{
  border:none;
  background-color:offwhite;
  font-size:22px;
  padding:20px 42px;
  text-transform:capitalize;
  cursor:pointer;
  transform:all 0.3s;
  position:relative;
  font-family: Montserrat;
  font-weight: 600;
}
.ready-to-download:before{
  content:"";
  position :absolute;
  width:24px;
  height:24px;
  top:-5px;
    left:-5px;
  border-top:2px solid #2945a2;
  border-left:2px solid #2945a2;
  transition:all 0.25s;
}
.ready-to-download:hover:before ,button:hover:after{
  width:103%;
  height:112%;
}
.ready-to-download:after{
  content:"";
  position :absolute;
  width:24px;
  height:24px;
  bottom:-5px;
    right:-5px;
  border-bottom:2px solid #2945a2;
  border-right:2px solid #2945a2;
  transition:all 0.30s;
}
.files-listing-div{
  width: 70%;
}
.ico-hover:hover{
  color:#2945a2;
}
.ico-bg{
  background-color: #2945a2;
  padding: 6px 10px;
  border-radius: 50%;
  transition:all 0.30s;
}
.ico-bg:hover{
  background-color: #2388f9;
}
.download-btn-a{
  text-decoration: none;
}
.ico-padding-top{
  padding-top: 12px!important;
}
.list-file-text{
  font-size: 16px;
}
.arimo{
  font-family: Arimo;
}
.fw-600{
  font-weight: 600;
}
.fw-500{
  font-weight: 500;
}
.logo-icon{
  width: 180px;
  height: auto;
}
.rem-4{
  margin-top: 4rem;
}
#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
  
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #c5c5c5;
	border: 2px solid #c5c5c5;
  
}
.item-text-short{
  width: 16em;
  overflow: hidden;
  text-overflow: ellipsis!important;
  white-space: nowrap;
}
.modal-header{
  border-bottom: none !important;
}
.btn-close:focus{
  box-shadow: none !important;
}
.pc-img{
  max-width: 200px;
  height: auto;
}
.mybtn-landing{
  background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
	margin: 10px;
	padding: 7px 20px;
  
	/*text-align: center;*/
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;            
	box-shadow: 0 0 20px #eee;
	font-weight: 700;
	/*display: block;*/
  border-radius: 25px;
  border:none;
  font-size: 15px;
}
.mybtn-landing:hover{
  background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}
.bg-success-page-card{
  background-color: #fff;
}
.copyLinkSec{
  background-color: #ebf1ff;
  
  margin-right: 10px;
  margin-left: 10px;
  border:#e2eafc;
  border-radius: 10px;
}
#copyLnk{
  background-color: #ebf1ff;
  border: none;
  color:#b1b4ba;
  padding-top: 12px;
}
#copyLnk:focus-visible{
  border: none!important;
  outline: -webkit-focus-ring-color auto 0px;
}
#copy-btn{
  
  border: none;
  padding: 6px 20px;
  color:#fff;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: 6px;
}
.copy-btn-color-blue{
  background-color:#5291ec;
}
.copy-btn-color-green{
  background-color: #2ff49a;
}
.border-right-link{
  border-right: 1px solid #f9f9f9;
  padding: 10px;
}
.text-color-link{
  color:#b1b4ba;
  font-size: 20px;
}
p{
  margin-bottom: 0px;
}
.feature-box{
  width: 276px;
}
.padding-100{
  padding-top: 380px;
}
.padding-80{
  padding-top: 350px;
}
.btn-in-video{
  background-color: #fff;
  padding: 14px 50px;
  border:none;
  position: absolute;
  top: 912px;
  left: 1043px;
  font-size: 20px;
  text-decoration: none;
  color:#000;

}
.barCompleted {
  background-color: #2945a2;
  
}
.btn-in-video:hover{
  padding: 12px 55px;
}
.video-bg-2nd-slide{
  
  width: 100%;
  height: auto;
  
}
.hr-box{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 0px;
  padding-top:10px;
  padding-bottom:10px;
}
@media only screen and (max-width: 600px) {
  body {
    text-align: center;
  }
  .files-listing-div{
  width: 100%;
}
.space-100{
  margin-top: 100px;
}
.rem-4{
  margin-top: 2rem;
}
.td-text-left{
  text-align: left!important;
}
}
/* file upload page */
.title-input{
  width: 100%;
  border:none;
  font-size: 15px;
  
  border-bottom: 1px solid #abb2b8;

}
.title-input::placeholder{
  color:#b7b8c1;
}
.title-input:focus-visible{
  outline: -webkit-focus-ring-color auto 0px;
  padding: 0px 8px;
}
.border-box-drag{
  border: 1px solid #abb2b8;
}
.border-box-drag-text{
  color:#adb4ba;
  font-size: 15px;
}
.folder-ico{
  color:#adb4ba;
}


.mybtn-upload{
  /* background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%); */
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
  
  
	margin: 5px;
	padding: 7px 20px;
  color: #fff;
	/*text-align: center;*/
	text-transform: capitalize;
	transition: 0.5s;
	background-size: 200% auto;
	            
	box-shadow: 0 0 20px #eee;
	font-weight: 700;
	/*display: block;*/
  border-radius: 5px;
  
  font-size: 15px;
}
.mybtn-upload:hover{
  background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}
.verify-btn-a{
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
  margin-top: 80px;
  width: 150px;
  color:#fff;
  border:none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.input-box:focus-visible{
  outline: -webkit-focus-ring-color auto 0px;
  padding: 0px 8px;
}
.box-loader-text{
  color:#adb4ba;
}
.description-input{
  width: 100%;
  border: 1px solid #abb2b8;
  font-size: 15px;
  height: 120px;
  color:#000;
}
.contact-main-text{
  font-size: 32px;
  color: #000;
  font-weight: 700;
}
.underline-contact-sec{
  background-color: #9e9e9e;
  height: 5px;
  width: 100px;
}
.social-media{
  background-color: #000;
  padding: 5px 10px;
  width: 45px;
  position: absolute;
  top: 1628px;
  left: 97%;
}
.sm-link-ico{
  color:#fff;
  font-size: 20px;
  margin-bottom: 15px;
}
.contact-text{
  color:#000;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.icon-frame{
  border: 3px solid #000;
  border-radius: 50%;
  padding: 10px 13px;
}
.icon-c{
  font-size: 20px;
}
.description-input::placeholder{
  color:#b7b8c1;
}
.description-input:focus-visible{
  outline: -webkit-focus-ring-color auto 0px;
  padding: 0px 8px;
}
.mybtn-upload-a{
  background-color: #2945a2;
  padding: 6px 10px;
  color:#fff;
  border:none;
  width:150px;
  font-size: 15px;
}
.btn-round-right{
  border-radius: 0px 25px 25px 0px;
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
}
.btn-round-left{
  border-radius: 25px 0px 0px 25px;
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
}
.box-loader{
  width: 250px;
  height: 150px;
}
.download-page-right-side{
  height: 100vh;
}
.download-page-right-side-img{
  background-image: url('https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667795973/Character_base_bfnavr.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
}
.download-page-left-text{
  font-weight: 700;
  margin-left: 75px;
  font-size: 45px;
  
}
.btn-download{
  background-color: #fff;
  border:none;
  padding:12px 35px;
  font-size: 18px;
  color:#000;
  font-weight: 600;
  margin-left: 75px;
}
.download-icon-d-page{
  font-weight: 600;
  color: #2395d3;
  font-size: 20px;

}
.first-text-d-page{
  color:#d8dff0;
  font-size: 28px;
  font-weight: 600;
  margin-left: 75px;
}
.list-text-d-page{
  color:#b2c4dc;
  margin-left: 75px;
  font-size: 20px;
  font-weight: 600;
}
.bullet-d-page{
  color:#32538d;
}
.send-now-btn-d-page{
  margin-left: 100px;
  border: none;
  color:#fff;
  background-color: #5c7ea6;
  font-size: 20px;
  font-weight: 700;
  padding: 5px 18px;
  border-radius: 10px;
}
.download-desktop-version-d-page{
  margin-right: 20px;
  background-color: transparent;
  color:#fff;
  border:2px solid #fff;
  border-radius: 5px 20px;
  padding: 8px 25px;
  font-size: 18px;
  font-weight: 700;
}
.btn-sec-d-page{
  margin-left: 100px;
}
.btn-invisible-bg{
  background-color: transparent;
  border: none;
}
.bar-ico-menu{
  font-size: 35px;
  font-weight: 700;
  color:#000;
  padding-right: 20px;
  
}
.offcanvas{
  background-color: #fff;
}
.logo-img-mob-menu{
  width: 125px;
  height: auto;
}
.dropdown-item{
  color: #000;
  /* font-size: 22px; */
}
ul.dropdown-menu-v{
  list-style-type: none;
  padding: 0;
}
.btn-mob-menu{
  padding: 10px 35px;
  color: #fff;
  background-image: linear-gradient(to top, #132862  0%,  #4581ae  100%);
  border:none;
}
.l-box-heading{
 /* display: flex;*/
 font-size: 1.2rem;
}
.l-card-txt{
  font-size: 16px;
}
.h-t-m{
  margin-top: 6rem;
}
.my-mobile-menu{
  background-color: #0a1940;
}
.spacer-75-for-d-pg{
  margin-top: 75px;
}
.spacer-10-for-d-pg{
  margin-top:50px;
}
.color-aaa{
  color: #aaa;
}
/*about us*/
.about-us-hero{
  background: linear-gradient(160deg, #59EBFF -17.07%, #59EBFF -45.06%, #00A3FF -17.05%, #00054E 105.17%);
  /* background-image: linear-gradient(139deg, #01064a 0%, #3b76a0 50%, #7af2ff 75%); */
}
.hero-heading-about{
  font-size: 42px;
  color:#fff;
  font-weight: 700;
  text-align: center;
}
.what-is-helibucket-heading{
  font-size: 36px;
  color:#01064a;
  font-weight: 700;
}
.what-is-helibucket-des{
  color:#545756;
  font-size: 16px;
  font-weight: 500;
}
.footer-heli{
  background-color: #0a1940;
}
.footer-text{
  text-decoration: none;
  color:#aaa;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.copyright-text{
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 5px;
  text-align: end;
}
.contact-form-ip{
  width: 80%;
  height: 40px;
  padding: 5px 10px;
  margin-left: 10%;
  margin-left: 10%;
  margin-bottom: 10px;
  border: 2px solid #c4c5d699;
  
}
.contact-form-msg{
  width: 80%;
  height: 120px;
  padding: 5px 10px;
  margin-left: 10%;
  margin-left: 10%;
  margin-bottom: 10px;
  border: 2px solid #c4c5d699;
}
.contact-form-btn{
  background-image: linear-gradient(to top, #132862 0%, #4581ae 100%);

  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 10px;
  border: none;
  color: #fff;
}
.ml-10{
  margin-left: 10%;
}
.page-loader{
  height: 100vh;
  width: 100%;
}
.loader-gif{
  width: 200px;
  height: auto;
}
.loader-pic-div{
  padding-top:35vh;
}


/**/
.full-width-box{
  max-width: 70%!important;
}

/*responsive*/
@media only screen and (max-width: 1080px) {
  .my-desktop-menu {
    display:none;
  }
  .padlock-img{
    height: auto;
    width: 80%;
   
  }
}
@media only screen and (min-width: 1080px) {
  .my-mobile-menu {
    display:none;
  }
}
/*right side img */


@media only screen and (max-width: 1400px) {
  .download-page-right-side-img{
    height: 600px;
  }
  .list-text-d-page{
    font-size: 16px;
  }
  .download-page-left-text{
    font-size: 35px;
  }
  
}


@media only screen and (max-width: 1200px) {
  .download-page-left-text{
    font-size: 30px;
  }
  .spacer-75-for-d-pg{
    margin-top: 50px;
  }
  .download-page-right-side-img{
    height: 500px;
  }
  .side-img-l-page {
    width:450px;
    height: auto;
  }
  .fsize-3rem{
    font-size: 2.1rem;
  }
  .feature-box{
    width: 250px;
  }
  .l-card-txt{
    font-size: 15px;
  }
  .padlock-img{
    height: 180px;
    width: auto;
   
  }
  .spacer-10-for-d-pg{
    margin-top:30px;
  }
  .download-page-right-side{
    display: none;
  }
  .downoad-page-logo{
    display: none;
  }
}



@media only screen and (max-width: 992px) {
  .downoad-page-logo{
    display:none;
  }
  .side-img-l-page {
    width:360px;
    height: auto;
  }
  .fsize-3rem{
    font-size: 1.8rem;
  }
  .feature-box{
    width: 220px;
  }
  .l-box-heading{
    font-size: 1rem;
  }
  .l-card-txt{
    font-size: 14px;
  }
  .mybtn-landing{
    font-size: 13px;
    padding: 5px 12px;
  }
  .download-page-right-side{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .side-img-l-page {
    display: none!important;
  }
  .right-img-sec{
    display: none!important;
  }
  .landing-page-heading{
    text-align: center;
  }
  .fsize-3rem{
    font-size: 2rem;
  }
  .feature-box{
    width: 250px;
  }
  .l-box-heading{
    font-size: 1.2rem;
  }
  .l-card-txt{
    font-size: 16px;
  }
  .mybtn-landing{
    font-size: 15px;
    padding: 7px 17px;
    
  }
  .l-pg-h-sp-c{
    color:#1a2980;
  }
  .otp-side-text{
    display: none;
  }
  .card-otp-success-upload{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .copyright-text{
    text-align: center;
  }
  .t-n-c-f{
    text-align: center;
  }
}
@media only screen and (max-width: 600px){
  body {
    text-align: left;
}
.bg-file-tsfr{
  width: 98%;
  margin-left:1%;
  margin-right: 1%;
}
.mybtn-upload-a{
  width: 136px;
  font-size: 13px;
}
.border-box-drag-text{
  font-size: 13px;
}
.mobile-logo{
  width: 150px;
}
.bar-ico-menu{
  color:#fff;
}
.logo-img{
  margin-top: 2px;
}
.verify-btn-a{
  margin-top: 88px;
  width:110px;
  color: #fff;
  border: none;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
}
.padlock-img{
  height: 150px;
  width: auto;
 
}
.title-fill{
  width:70%;
}
.first-text-d-page{
  margin-left: 10px;
  font-size: 20px;
}
.list-text-d-page{
  margin-left: 10px;
  color: #01064a;
  font-size: 14px;
}
.bullet-d-page{
  color: #fff;
}
.send-now-btn-d-page{
  margin-left: 26px;
  font-size: 16px;
}
.download-page-left-text{
  text-align: center;
  margin-left: 0;
  font-size: 25px;
}
.btn-download{
  margin-left: 0;
  padding: 8px 25px;
  font-size: 18px;
}
.d-btn-outer-div{
  text-align: center;
}
}

@media only screen and (max-width: 560px) {
  .side-img-l-page {
    display: none!important;
  }
  .right-img-sec{
    display: none!important;
  }
  .landing-page-heading{
    text-align: center;
  }
  .fsize-3rem{
    font-size: 1.2rem;
  }
  .feature-box{
    width: 100%;
  }
  .l-box-heading{
    font-size: 1.2rem;
  }
  .l-card-txt{
    font-size: 16px;
  }
  .mybtn-landing{
    font-size: 16px;
    padding: 7px 17px;
    
  }
  .l-pg-h-sp-c{
    color:#1a2980;
  }
  .h-t-m{
    margin-top: 1rem;
  }
  
  #tsparticles{
    display: none;
  }
  .contact-map{
    width: 90%;
    height: 200px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
/* @media only screen and (max-width: 516px) {
  .side-img-l-page {
    display: none!important;
  }
  .right-img-sec{
    display: none!important;
  }
  .landing-page-heading{
    text-align: center;
  }
  .fsize-3rem{
    font-size: 1.8rem;
  }
  .feature-box{
    width: 210px;
  }
  .l-box-heading{
    font-size: 1rem;
  }
  .l-card-txt{
    font-size: 14px;
  }
  .mybtn-landing{
    font-size: 13px;
    padding: 5px 13px;
    
  }
  .l-pg-h-sp-c{
    color:#1a2980;
  }
} */
.left-img-landing{
  width: 100%;
  height: auto;
}