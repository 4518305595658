
.edit-profile-box{
    width:48%;
    height:35px;
    padding-left:10px;
    background-color: transparent;
    border: none;
    
    color: #fff;
    border-bottom: 1px solid #ffffff99;
}
.edit-profile-box-s{
    width:98%;
    height:35px;
    padding-left:10px;
    background-color: transparent;
    border: none;
    
    color: #fff;
    border-bottom: 1px solid #ffffff99;
}
.profile-page-btn{
    border: none;
    margin-top: 10px;
    padding:6px 20px;
    padding-bottom: 5px;
    background-color: #5e72e4;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

/*verify page*/
.verify-page{
    height:100vh;
    width: 100%;
    
}
.verifyinput{
    max-width: 400px;
    
}